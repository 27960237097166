export const SITE_NAME = 'Dobon - playing card game'
export const DESCRIPTION = 'ドボンはカードを場に出し合い、手札の計算値を合致させるトランプゲームです。'
export const DOBON_ROOM_STATUS_WAITING = 0 // 参加者待機中
export const DOBON_ROOM_STATUS_CLOSE = 1 // 参加者締め切り状態
export const DOBON_ROOM_STATUS_PLAYING = 2 // ゲーム実施中
export const DOBON_ROOM_STATUS_ENDED = 3 // ゲーム終了
export const DOBON_ROOM_STATUS_DELETED = 4 // 非開催、削除済
export const DOBON_CARD_NAME_JA_C = 'クラブ'
export const DOBON_CARD_NAME_JA_D = 'ダイヤ'
export const DOBON_CARD_NAME_JA_S = 'スペード'
export const DOBON_CARD_NAME_JA_H = 'ハート'
export const DOBON_CARD_NAME_JA_X = 'ジョーカー'
export const DOBON_CARD_NUMBER_JOKER = 0
export const DOBON_CARD_NUMBER_SKIP = 1
export const DOBON_CARD_NUMBER_DRAW_2 = 2
export const DOBON_CARD_NUMBER_WILD = 8
export const DOBON_CARD_NUMBER_REVERSE = 11
export const DOBON_CARD_NUMBER_OPENCARD = 13
export const DOBON_JUDGE_NUMBER_JOKER = 21
export const DOBON_NUMBER_OF_FACE_CARD = 14

export const PVE_UNREGISTERED_NAMES = 'あなた'
export const PVE_COM_USER_NAMES = ['com1', 'com2', 'com3']
